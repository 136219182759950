<template>
    <div class="flex_grow_1" >
			<div >
				<div class="PTB_3 bg_fff fontS_17 textC">授权中心</div>
				<span class="sx" @click="goTo()">筛选</span> 
			</div>
        
			<div>
				<div class="MT_2" v-if="msgList.length>0">
						<div class="flex_grow_1 bg_fff" @click="toPage(item)" v-for="(item,index) in msgList" :key="index">
								<div class="PLR_2">
										<div class="flex_row_between PT_2"  >
												<div class="a" v-if="item.message_type == 1">
												<img class="imgItem" src="../../assets/msg_1.png" alt="">  
												<div v-if="item.status == 1" class="yd"></div>
												</div>
												<div class="a" v-if="item.message_type == 2">
														<img class="imgItem" src="../../assets/msg_2.png" alt="">
														<div v-if="item.status == 1" class="yd"></div> 
												</div>
										<div class="a" v-if="item.message_type == 3">
														<img class="imgItem" src="../../assets/msg_3.png" alt="">
														<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 4" >
														<img class="imgItem" src="../../assets/msg_4.png" alt="">
														<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 5">
												<img  class="imgItem" src="../../assets/msg_5.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 6">
												<img class="imgItem" src="../../assets/msg_6.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
										<div class="a" v-if="item.message_type == 7">
												<img class="imgItem" src="../../assets/msg_7.png" alt="">
												<div v-if="item.status == 1" class="yd"></div>
										</div>
												<div class="flex_grow_1 ML_4 BB_1_f2f2f2 PTB_3">
														<div class="flex_row_between">
																<span class="fontS_16 font_weight_bold">{{ item.message_type | msgType}}</span>
																<div class="flex">
																		<img class="icon" src="../../assets/time.png" alt="">
																		<span>{{item.date1}}</span> 
																</div>
														</div>
														<div class="color_555 textL PT_2">
																来自:{{item.company_name == "" ? "暂无" : item.company_name }}
														</div>
												</div>
										</div>
								</div>
						</div>
						<div v-if="nomore == true" class="nomore">没有更多数据~</div>
						<div style="height:1.4rem;"></div>
				</div>
				<div style="text-align:center;margin-top:2rem;" v-else>
						<div v-if="showLoading == false">
								<img class="img" src="../../assets/nomessage.png" alt="">
						<div class="text">- 很抱歉,这里没有任何消息 - </div>
				</div>
						
				</div>
				<van-loading v-if="showLoading" size="45" class="loading" type="spinner" />
			</div>
			
    </div>
</template>

<script>
import '@/assets/css/common.css';
import { Toast } from 'vant'
import getDate from "../../util/dateChange"
export default {
	name: "",
	data() {
		return {
			msgList:[], // 消息列表
			page:1,			// 请求页码
			count:null,	// 消息数量
			nomore:false,  
			showLoading:false, //控制加载中显示隐藏
			type:"", //请求消息类型
			isLoading:false,
		
		}
	},
	
	mounted() {
			let type = this.$route.query.type6
			let page = this.page
			this.type = type
			this.getMsgList(type,page)

				window.onscroll = ()=>{
					var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
					var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
					var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
					if( scrollTop + windowHeight >= scrollHeight - 1){
							page++
							
							if(this.msgList.length != this.count){
									this.getMsgList(type,page)
							}else{
									this.nomore = true
							}
							
				}}
				
		},
	methods: {
		// onRefresh(){
		// 	let type = this.$route.query.type
		// 	let page = this.page
		// 	console.log(type, page)
		// 	this.msgList = []
		// 	this.getMsgList(type,page)
		// },

		isShow(){
			this.show = false
		},

		getMsgList(type,pageno){
			this.showLoading = true
			this.$http.post("/user/v1/message/messageList",{reqType:"messageList",pageno:pageno,limit:7,type:type})
			.then((res)=>{
				this.showLoading = false
				this.isLoading = false
				let response = JSON.parse(res.data)
			console.log(response)
				if(response.code == 0){
						response.data.messageList.map((value)=>{
								value.add_time = value.add_time.replace(/-/g,'/')
								let date = new Date(value.add_time)
								let date1 = getDate(date,0)
								value.date1 = date1
								return value
						})
						this.msgList = this.msgList.concat(response.data.messageList)
						
						this.count = response.data.count
				}else{
						Toast.fail(response.msg)
				}	
			})
			.catch((e)=>{console.log(e)})
		},

		goTo() {
				console.log(1)
				this.$router.push({
						path:'/search',
						query:{
								type:'筛选'
						}
				});
		},

		ReplaceUrl(text){
			var re =/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
			var s = text.match(re);
			let url = s[0]
			return url;
		},

		toPage(item) {
				// console.log(item)
				let id = item.id
				console.log(id)
				this.$http.post("/user/v1/message/messageSign",{reqType:"messageSign",messageId:id,type:1})
				.then((res)=>{
						let response = JSON.parse(res.data)
						console.log(response)
						if(response.code == 0){
							console.log(response)
								if(item.message_type == 1 || item.message_type == 5){
										// let type = this.$route.query.type6
										// let page = this.page
										// this.type = type
										// this.msgList = []
										// this.getMsgList(type,page)
										let a = this.ReplaceUrl(item.content)
										window.location.href = a 

								}else if(item.message_type == 6){
										this.$router.push({
											path:'/commentDetail',
											query:{
													id1:item.evaluation_id,
													type1:"msg",
													comp:item.company_name,
													
													// companyName2:item.company_name
											}
										})
								}else{
									this.$router.push({
										path:'/messageDetail',
										query:{
												msgDetail: JSON.stringify(item) 
										}
									});
							}
						}else{
								Toast.fail(response.msg)
						}
				})
				.catch((e)=>{console.log(e)})
		},
			
	},
}
</script>

<style scoped>
.sx{
    position:absolute;
    top:0.35rem;
    right:0.2rem;
    font-size: 0.3rem;
}
.a{
    position:relative;
}
.yd{
    width: 0.2rem;
    height: 0.2rem;
    background: #F15641; 
    position:absolute; 
    left:0.7rem;
    top:0.05rem;
    border-radius: 50%;
}
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 2rem;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.img{
    width:3rem;
    height:2.7rem;
}
.imgItem{
   /* position:relative; */
    width: 0.8rem;
    height: 0.8rem;
}
.icon{
    width:0.25rem;
    height:0.25rem;
    margin-right:0.08rem;
}
.flex{
    display:flex;
    align-items: center;
}
.text{
    font-size: 0.28rem;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #333333;
margin-top:0.5rem;
}
.txt{
    font-size: 0.2rem;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #333333;
margin-top:0.05rem;
}
.nomore{
 text-align: center;
 color:#ddd;
 margin-top:0.3rem;
}
.loading{
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-0.2rem;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 200px;
    height: 120px;
    background-image: url("../../assets/luobo.png");
    background-repeat:no-repeat;
    background-size:3.5rem 2rem;
  }
</style>